import ReactMarkdown from "react-markdown";
import remarkPluginBreaks from "remark-breaks";

import { TAsyncResult } from "../useAsyncValue";
import { TRoute } from "../types";
import { imprintMarkdown } from "./imprintMarkdown";

export function ImprintTitle({
  dataResult,
  routeParams,
}: {
  dataResult: TAsyncResult<null>;
  routeParams: {};
}) {
  return <>Impressum</>;
}

const remarkPlugins = [remarkPluginBreaks];

export function ImprintContent(props: {
  dataResult: TAsyncResult<null>;
  routeParams: {};
}) {
  return (
    <>
      <ReactMarkdown remarkPlugins={remarkPlugins}>
        {imprintMarkdown}
      </ReactMarkdown>
    </>
  );
}

export const imprintRoute: TRoute<any, any> = {
  path: "/imprint",
  Title: ImprintTitle,
  Content: ImprintContent,
  authenticated: false,
};
