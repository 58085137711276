import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

const PREFIX = "EmptyListText";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
}));

export function EmptyListText({ children }: { children: ReactNode }) {
  return (
    <StyledTypography variant="subtitle1" className={classes.root}>
      {children}
    </StyledTypography>
  );
}
