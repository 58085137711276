import { TRoute } from "../types";

export const tenantLocationsListRoute: TRoute<any, any> = {
  path: "/tenants/:tenantKey/locations",
  redirect: (params, selectedTenant) =>
    selectedTenant ? `/tenants/${selectedTenant}/overview` : null,
};

export const locationsListRoute: TRoute<any, any> = {
  path: "/locations",
  redirect: (params, selectedTenant) =>
    selectedTenant ? `/tenants/${selectedTenant}/overview` : null,
};
