import { useTranslation } from "react-i18next";
import { Box, Tooltip } from "@mui/material";
import { TLocationDoor, TOrder, TResourceTypes } from "../api";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
//import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Link } from "react-router-dom";
import { DoorReservation } from "./index";
import { DateTime } from "luxon";
import ResourceTypeDialog from "./ResourceTypeDialog";

interface DoorCardProps {
  door: TLocationDoor;
  nextReservation: DoorReservation | null;
  nextOrder: TOrder | null;
  tenantKey: string;
  resourceTypes: TResourceTypes;
  selected: boolean;
  resourceType?: string;
  locationID: string;
  handleCardClick: (doorId: string | number) => void;
  handleLockClick: (doorId: string | number) => void;
  isAllowedToOpenDoor: boolean;
}

const DoorCardBox = styled(Box)(({ theme }) => ({
  minWidth: "225px",
  width: "calc(25% - 15px)",
  minHeight: "200px",
  borderRadius: "30px",
  backgroundColor: "#b7b8b8",
  padding: "15px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "@media (max-width: 1037px)": {
    width: "calc(33% - 14px)",
  },
  "@media (max-width: 792px)": {
    width: "calc(50% - 10px)",
    minHeight: "0",
  },
  "@media (max-width: 531px)": {
    width: "100%",
    minWidth: "100%",
    minHeight: "0",
  },
}));

const Row = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  alignItems: "baseline",
}));

const Column = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
  paddingBottom: "10px",
  marginBottom: "auto",
}));

const CardCheckbox = styled("input")(({ theme }) => ({
  float: "right",
  margin: "0px 0px auto auto",
  minHeight: "25px",
  minWidth: "25px",
  cursor: "pointer",
  accentColor: theme.palette.primary.main,
}));

const NoOverflowTypography = styled(Typography)(({ theme }) => ({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  flex: "1",
  minWidth: "0",
  width: "100%",
}));

/*
const MoreIcon = styled(MoreHorizIcon)(({theme}) => ({
    float: 'right',
    margin: 'auto 0px auto auto',
    cursor: 'pointer',
}));
*/

export const DoorCard = (props: DoorCardProps) => {
  const { t } = useTranslation();

  const formattedDateTime = (): string => {
    if (!props.nextReservation) {
      return "";
    }
    const createdAt = DateTime.fromMillis(props.nextReservation.createdAt);
    const expiredAt = DateTime.fromMillis(props.nextReservation.expiredAt);
    const today = DateTime.now().toISODate();

    const start =
      createdAt.toISODate() === today
        ? createdAt.toFormat("H:mm")
        : createdAt.toFormat("dd.M.yyyy, H:mm");
    const end =
      expiredAt.toISODate() === today
        ? expiredAt.toFormat("H:mm")
        : expiredAt.toFormat("dd.M.yyyy, H:mm");
    return `${start} - ${end}`;
  };

  const formattedContactPerson = (): string => {
    if (!props.nextOrder) {
      return "";
    }
    let result = "";
    const contact =
      props.nextOrder.tikiOrder?.source === "Regiondo"
        ? props.nextOrder.regiondoOrder?.contact_data
        : props.nextOrder.order?.raw?.contact_data;
    if (contact?.firstname && contact?.lastname) {
      result = contact.firstname + " " + contact.lastname;
    }
    return result;
  };

  return (
    <DoorCardBox>
      <Row>
        <Typography variant={"h3"}>
          {(props.door.doorID.length < 2 ? "0" : "") + props.door.doorID}
        </Typography>
        {props.resourceType && (
          <Tooltip title={props.resourceType}>
            <NoOverflowTypography variant={"h5"}>
              {" "}
              {props.resourceType}{" "}
            </NoOverflowTypography>
          </Tooltip>
        )}
        <CardCheckbox
          type={"checkbox"}
          checked={props.selected}
          onChange={() => {
            props.handleCardClick(props.door.doorID);
          }}
        />
      </Row>

      <Column>
        {props.nextReservation && (
          <Tooltip title={props.nextReservation.rental.orderID}>
            <NoOverflowTypography variant={"h5"}>
              {props.nextReservation.rental.orderID.includes("admin-") ? (
                <span>{props.nextReservation.rental.orderID}</span>
              ) : (
                <Link
                  to={`/tenants/${props.tenantKey}/orders/${props.nextReservation.rental.orderID}`}
                >
                  {props.nextReservation.rental.orderID}
                </Link>
              )}
            </NoOverflowTypography>
          </Tooltip>
        )}
        {props.nextOrder && (
          <Tooltip title={formattedContactPerson()}>
            <NoOverflowTypography variant={"h5"}>
              {formattedContactPerson()}
            </NoOverflowTypography>
          </Tooltip>
        )}
        {props.nextReservation && (
          <Tooltip title={formattedDateTime()}>
            <NoOverflowTypography variant={"h5"}>
              {formattedDateTime()}
            </NoOverflowTypography>
          </Tooltip>
        )}
      </Column>

      <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Tooltip
          title={
            props.door.openState === "closed"
              ? t("doorCard.unlock")
              : t("doorCard.lock")
          }
          onClick={() =>
            props.isAllowedToOpenDoor
              ? props.handleLockClick(props.door.doorID)
              : null
          }
          style={{
            cursor: props.isAllowedToOpenDoor ? "pointer" : "not-allowed",
          }}
        >
          {props.door.openState === "closed" ? (
            <LockIcon viewBox={"4 0 16 21"} />
          ) : (
            <LockOpenIcon />
          )}
        </Tooltip>
        {/* TODO reactivate if further functionality is implemented
                    <Tooltip title={t('doorCard.more')}>
                        <MoreIcon onClick={() => {
                        }}/>
                    </Tooltip>
                */}
        <ResourceTypeDialog
          resourceType={props.resourceType}
          resourceTypes={props.resourceTypes}
          tenantKey={props.tenantKey}
          doorID={props.door.doorID}
          locationID={props.locationID}
        />
      </Row>
    </DoorCardBox>
  );
};
