import { ReactNode, useCallback } from "react";
import { AppState, Auth0Provider, User } from "@auth0/auth0-react";
import { useNavigate } from "react-router";

export type TAccessToken$Permissions = string[];

export type TAccessToken = {
  token: string;
  decoded: { permissions: TAccessToken$Permissions };
};

export function getTenantsFromToken(
  permissions: TAccessToken$Permissions,
): string[] {
  const prefix = "tenant:";
  return permissions
    .filter((permission) => permission.startsWith(prefix))
    .map((permission) => permission.substring(prefix.length));
}

export function doesTokenAllowShowOrder(
  permissions: TAccessToken$Permissions,
): boolean {
  return permissions.some((permission) => permission === "show:booking");
}

export function doesTokenAllowOpenLocationOrAnyDoor(
  permissions: TAccessToken$Permissions,
  locationID: string,
): boolean {
  const openLocationDoorPermissionRegExp =
    buildOpenLocationDoorPermissionRegExp(locationID, "[0-9]+");

  return permissions.some(
    (permission) =>
      permission === `open:location:${locationID}` ||
      permission.match(openLocationDoorPermissionRegExp),
  );
}

export function doesTokenAllowOpenLocationDoor(
  permissions: TAccessToken$Permissions,
  locationID: string,
  doorID: string,
): boolean {
  const openLocationDoorPermissionRegExp =
    buildOpenLocationDoorPermissionRegExp(locationID, doorID);

  return permissions.some(
    (permission) =>
      permission === `open:location:${locationID}` ||
      permission.match(openLocationDoorPermissionRegExp),
  );
}

function buildOpenLocationDoorPermissionRegExp(
  locationID: string,
  doorIDRegExpPart: string,
) {
  return new RegExp(
    `^open:location:${locationID}:door:${doorIDRegExpPart}$`,
    "g",
  );
}

export function Auth0ProviderWithHistory({
  children,
}: {
  children: ReactNode;
}) {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback<
    (appState?: AppState, user?: User) => void
  >(
    (appState) => {
      navigate(appState?.returnTo || window.location.pathname);
    },
    [navigate],
  );

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
      }}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}
