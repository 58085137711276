import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { ComponentProps, useCallback, useState } from "react";
import { Location, useLocation } from "react-router";
import { RequestError } from "./api";
import { TLocationState } from "./types";
import { useTranslation } from "react-i18next";

export function ToastMessage({
  message,
  severity,
  elevation,
  action,
}: {
  message: string | Error;
  severity: Exclude<ComponentProps<typeof MuiAlert>["severity"], undefined>;
  elevation?: number;
  action?: Exclude<ComponentProps<typeof MuiAlert>["action"], undefined>;
}) {
  const [isOpen, setIsOpen] = useState(!!message);
  const handleClose = useCallback(() => setIsOpen(false), []);

  return (
    <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
      <MuiAlert
        elevation={elevation ?? 6}
        variant="filled"
        severity={severity ?? "error"}
        onClose={handleClose}
        action={action}
      >
        {typeof message === "string" ? message : message.message}
      </MuiAlert>
    </Snackbar>
  );
}

export function RequestErrorToast({
  requestError,
}: {
  requestError: RequestError;
}) {
  const {t} = useTranslation();
  const { loginWithRedirect } = useAuth0();
  const location: Location<TLocationState> = useLocation();

  const handleClickLogin = useCallback(() => {
    loginWithRedirect({ appState: { returnTo: location.pathname } });
  }, [location.pathname, loginWithRedirect]);

  if (requestError.response?.status === 401) {
    return (
      <ToastMessage
        message={t('message.session_expired')}
        severity="error"
        action={
          <Button color="inherit" size="small" onClick={handleClickLogin}>
            Login
          </Button>
        }
      />
    );
  }
  return <ToastMessage message={requestError.message} severity="error" />;
}
