import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TAPI, getLocation } from "../api";
import { TAsyncResult } from "../useAsyncValue";

import { TRoute } from "../types";
import { useTranslation } from "react-i18next";
import { RequestErrorToast } from "../ToastMessage";
import { CircularProgress } from "@mui/material";
import { DateTime } from "luxon";

type LoadedPageData = Awaited<ReturnType<typeof loadLocationStatus>>;

export function locationStatusTitle({
  dataResult,
}: {
  dataResult: TAsyncResult<LoadedPageData>;
  routeParams: { locationID: string };
}) {
  return dataResult.type === "RESOLVED" ? (
    <>{dataResult.value.name}</>
  ) : (
    <>...</>
  );
}

export async function loadLocationStatus(
  api: TAPI,
  { locationID, tenantKey }: { locationID: string; tenantKey: string },
) {
  return getLocation(api, tenantKey, locationID);
}

export function LocationStatusContent({
  dataResult: locationDataResult,
  routeParams,
}: {
  dataResult: TAsyncResult<LoadedPageData>;
  routeParams: { tenantKey: string; locationID: string };
}) {
  const { t } = useTranslation();

  if (locationDataResult.type === "NONE") {
    return null;
  }

  if (locationDataResult.type === "REJECTED") {
    return <RequestErrorToast requestError={locationDataResult.error} />;
  }

  if (locationDataResult.type === "PENDING") {
    return (
      <CircularProgress
        sx={{
          display: "block",
          margin: "auto",
          marginTop: 4,
          marginBottom: 4,
        }}
      />
    );
  }

  return (
    <TableContainer
      sx={{ margin: "50px auto", width: "90%" }}
      component={Paper}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("location_status.reason")}</TableCell>
            <TableCell>{t("location_status.status")}</TableCell>
            <TableCell>{t("location_status.time")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {locationDataResult.value.connectionEvents?.map((row, key) => (
            <TableRow
              key={key}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.disconnectReason}
              </TableCell>
              <TableCell>{row.eventType}</TableCell>
              <TableCell>
                {DateTime.fromSeconds(row.timestamp).toLocaleString(
                  DateTime.DATETIME_SHORT_WITH_SECONDS,
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export const locationStatusRoute: TRoute<
  any,
  { locationID: string; tenantKey: string }
> = {
  path: "/tenants/:tenantKey/location/:locationID/status",
  Title: locationStatusTitle,
  Content: LocationStatusContent,
  loadData: loadLocationStatus,
};
