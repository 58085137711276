import { locationsListRoute, tenantLocationsListRoute } from "./locations-list";
import {
  locationDetailsRoute,
  tenantLocationDetailsRoute,
} from "./location-details";
import {
  orderDetailsRoute,
  ordersRoute,
  tenantOrderDetailsRoute,
  tenantOrdersRoute,
} from "./orders-details";
import { imprintRoute } from "./imprint";
import { TRoute } from "./types";
import { tenantDetailsRoute } from "./tenant-details";
import {
  areaOverviewRoute,
  locationOverviewRoute,
  overviewRoute,
  tenantOverviewRoute,
} from "./doors-overview";
import { locationStatusRoute } from "./location-status";

const indexRoute: TRoute<Record<string, never>, Record<string, never>> = {
  path: "/",
  redirect: (params, initialTenant) =>
    initialTenant
      ? {
          to: `/tenants/${initialTenant}`,
          replace: true,
        }
      : null,
};

export const routes: TRoute<any, any>[] = [
  locationsListRoute,
  tenantLocationsListRoute,
  tenantDetailsRoute,
  tenantLocationDetailsRoute,
  locationDetailsRoute,
  tenantOverviewRoute,
  locationOverviewRoute,
  areaOverviewRoute,
  overviewRoute,
  orderDetailsRoute,
  tenantOrderDetailsRoute,
  ordersRoute,
  tenantOrdersRoute,
  imprintRoute,
  indexRoute,
  locationStatusRoute,
];
