import React from 'react';
import { useTranslation } from 'react-i18next';
import {FormControl, MenuItem, Select} from '@mui/material';
import {theme} from "../theme";

const flags = {
  en: '🇬🇧 English',
  de: '🇩🇪 Deutsch',
};

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [lng, setLng] = React.useState(i18n.language.split('-')[0]);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <FormControl fullWidth size={'small'} color={'primary'} sx={{backgroundColor: theme.palette.secondary.light}}>
        <Select
            labelId="languageSelectorLabel"
            id="languageSelector"
            value={lng}
            onChange={(e) => {
                changeLanguage(e.target.value);
                setLng(e.target.value)
            }}
        >
            <MenuItem value={'en'}>{flags.en}</MenuItem>
            <MenuItem value={'de'}>{flags.de}</MenuItem>
        </Select>
    </FormControl>
  );
};

export default LanguageSelector;
