import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { getOrder, useAPI } from "../api";
import { doesTokenAllowShowOrder } from "../auth";

import { TAsyncResult, useAsyncValue } from "../useAsyncValue";
import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import Search from "@mui/icons-material/Search";
import ArrowForwardRounded from "@mui/icons-material/ArrowForwardRounded";
import { TRoute } from "../types";
import WarningRounded from "@mui/icons-material/WarningRounded";
import { OrderDetailsSections } from "./OrderDetailsSections";
import { ErrorBoundary } from "./ErrorBoundary";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";

const NoResultsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  "& > *:nth-child(2)": {
    marginLeft: theme.spacing(1),
  },
}));
const SearchFormRoot = styled("form")(({ theme }) => ({
  display: "grid",
  gridAutoFlow: "column",
  gridTemplateColumns: "auto min-content",
}));

export function OrderDetailsTitle({
  routeParams: { orderId },
}: {
  dataResult: TAsyncResult<null>;
  routeParams: { orderId?: string; tenantKey: string };
}) {
  const {t} = useTranslation();
  return <>{orderId ? `${t('common.order')} #${orderId}` : t('order_details.find_order')}</>;
}

export function OrderDetailsContent({
  routeParams: { orderId, tenantKey },
}: {
  dataResult: TAsyncResult<null>;
  routeParams: { orderId?: string | undefined; tenantKey: string };
}) {
  const {t} = useTranslation();
  const api = useAPI();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(orderId ?? "");
  const handleSearchInputChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >((event) => setSearchValue(event.target.value), []);

  const handleFormSubmit = useCallback<FormEventHandler>(
    (event) => {
      event.preventDefault();
      navigate(`/tenants/${tenantKey}/orders/${searchValue}`, {
        replace: true,
      });
    },
    [navigate, searchValue, tenantKey],
  );

  const isShowingOrdersAllowed = useMemo(
    () => doesTokenAllowShowOrder(api.getPermissions()),
    [api],
  );
  const promiseGetOrder = useMemo(
    () =>
      orderId && isShowingOrdersAllowed
        ? getOrder(api, tenantKey, orderId)
        : null,
    [api, isShowingOrdersAllowed, orderId, tenantKey],
  );
  const asyncResultGetOrder = useAsyncValue(promiseGetOrder);

  const [isJSONCollapsed, setIsJSONCollapsed] = useState(true);
  const handleJSONCollapseToggle = useCallback(
    () => setIsJSONCollapsed((current) => !current),
    [],
  );

  return (
    <Box sx={{paddingTop: "24px", paddingBottom: "24px"}}>
      <Container>
        <SearchFormRoot onSubmit={handleFormSubmit}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel htmlFor="order-details-page-search-input">
              {t('order_details.order_id')}
            </InputLabel>
            <Input
              id="order-details-page-search-input"
              disabled={!isShowingOrdersAllowed}
              value={searchValue}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              endAdornment={
                <IconButton
                  color="primary"
                  type="submit"
                  disabled={searchValue === ""}
                  size="large"
                >
                  <ArrowForwardRounded />
                </IconButton>
              }
              onChange={handleSearchInputChange}
            />
          </FormControl>
        </SearchFormRoot>

        {!isShowingOrdersAllowed ? (
          <NoResultsContainer>
            <WarningRounded color="error" />
            <Typography variant="subtitle1" color="error">
              {t('order_details.no_viewing_permission')}
            </Typography>
          </NoResultsContainer>
        ) : (
          <>
            {asyncResultGetOrder.type === "PENDING" && (
              <NoResultsContainer>
                <CircularProgress />
              </NoResultsContainer>
            )}
            {asyncResultGetOrder.type === "REJECTED" && (
              <NoResultsContainer>
                <WarningRounded color="error" />
                <Typography variant="subtitle1" color="error">
                  {t('order_details.order_not_found')}
                </Typography>
              </NoResultsContainer>
            )}
          </>
        )}
        {asyncResultGetOrder.type === "RESOLVED" && (
          <>
            <ErrorBoundary>
              <OrderDetailsSections order={asyncResultGetOrder.value} />
            </ErrorBoundary>

            <Card sx={{ width: "100%" }}>
              <CardHeader
                title={
                  <Box
                    sx={{
                      padding: "none",
                      display: "flex",
                      alignItems: "center",
                      "& > *:first-child": {
                        flex: "1",
                      },
                    }}
                  >
                    <span>JSON</span>
                    {isJSONCollapsed ? <ExpandMore /> : <ExpandLess />}
                  </Box>
                }
                onClick={handleJSONCollapseToggle}
                sx={{ cursor: "pointer" }}
              />
              <Collapse in={!isJSONCollapsed}>
                <CardContent>
                  <SyntaxHighlighter language="json" style={docco}>
                    {JSON.stringify(asyncResultGetOrder.value, null, 2)}
                  </SyntaxHighlighter>
                </CardContent>
              </Collapse>
            </Card>
          </>
        )}
      </Container>
    </Box>
  );
}

export const tenantOrdersRoute: TRoute<any, { tenantKey: string }> = {
  path: "/tenants/:tenantKey/orders",
  Title: OrderDetailsTitle,
  Content: OrderDetailsContent,
};

export const ordersRoute: TRoute<any, { orderId: string }> = {
  path: "/orders",
  redirect: (params, selectedTenant) =>
    selectedTenant ? `/tenants/${selectedTenant}/orders` : null,
};

export const tenantOrderDetailsRoute: TRoute<
  any,
  { tenantKey: string; orderId: string }
> = {
  path: "/tenants/:tenantKey/orders/:orderId",
  backPath: "/tenants/:tenantKey/orders",
  Title: OrderDetailsTitle,
  Content: OrderDetailsContent,
};

export const orderDetailsRoute: TRoute<any, { orderId: string }> = {
  path: "/orders/:orderId",
  redirect: ({ orderId }, selectedTenant) =>
    selectedTenant
      ? {
          to: `/tenants/${selectedTenant}/orders/${orderId}`,
          replace: true,
        }
      : null,
};
