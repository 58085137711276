import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useState } from "react";
import { TResourceTypes, updateResourceType, useAPI } from "../api";

const DialogButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

type Props = {
  resourceTypes: TResourceTypes;
  resourceType?: string;
  tenantKey: string;
  locationID: string;
  doorID: string;
};

export default function ResourceTypeDialog({
  resourceTypes,
  resourceType,
  tenantKey,
  locationID,
  doorID,
}: Props) {
  const [open, setOpen] = useState(false);
  const [currentResourceType, setCurrentResourceType] = useState<string>(
    resourceType ?? "",
  );

  const { t } = useTranslation();
  const api = useAPI();

  const handleChange = (
    event: SelectChangeEvent<typeof currentResourceType>,
  ) => {
    setCurrentResourceType(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOk = async (
    event: React.SyntheticEvent<unknown>,
    reason?: string,
  ) => {
    if (reason !== "backdropClick") {
      await updateResourceType(
        api,
        tenantKey,
        locationID,
        doorID,
        currentResourceType,
      );
      setOpen(false);
      window.location.reload();
    }
  };

  const handleClose = (
    event: React.SyntheticEvent<unknown>,
    reason?: string,
  ) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  return (
    <div>
      <DialogButton onClick={handleClickOpen}>
        <MoreHorizIcon />
      </DialogButton>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>{t("location_details.resource_type")}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            <FormControl sx={{ m: 1, minWidth: 180 }}>
              <Select value={currentResourceType} onChange={handleChange}>
                {resourceTypes.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <DialogButton onClick={handleClose}>
            {t("location_details.cancel")}
          </DialogButton>
          <DialogButton onClick={handleOk}>
            {t("location_details.ok")}
          </DialogButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
