import { Component, ReactNode } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import WarningRounded from "@mui/icons-material/WarningRounded";
import Typography from "@mui/material/Typography";

const PREFIX = "ErrorBoundary";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    "& > *:first-child": {
      marginRight: theme.spacing(2),
    },
  },
}));

type TProps = { children: ReactNode };

export class ErrorBoundary extends Component<TProps, { error: Error | null }> {
  constructor(props: TProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    if (this.state.error) {
      return <ErrorMessage error={this.state.error} />;
    }

    return this.props.children;
  }
}

function ErrorMessage({ error }: { error: Error }) {
  return (
    <StyledBox className={classes.root}>
      <WarningRounded color="error" />
      <Typography color="error" variant="subtitle1">
        {error.message}
      </Typography>
    </StyledBox>
  );
}
