import { alpha, createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: "'Source Sans 3', sans-serif",
  },
  palette: {
    primary: {
      main: "#f7b753",
      contrastText: "#3B3C3D",
      light: "#EBECEA",
    },
    secondary: {
      main: "#feca31",
      dark: "#3B3C3D",
      light: "#b9b9b9",
    },
    action: {
      hover: alpha("#5e5c59", 0.2),
      focus: alpha("#5e5c59", 0.2),
    },
  },
});
