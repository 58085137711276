import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import ListItemText from "@mui/material/ListItemText";
import copy from "copy-to-clipboard";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
const PREFIX = "DataListItem";

const classes = {
  root: `${PREFIX}-root`,
  singleLineText: `${PREFIX}-singleLineText`,
  singleLineCode: `${PREFIX}-singleLineCode`,
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  [`&.${classes.root}`]: {
    cursor: "pointer",
    transition: theme.transitions.create("background-color", {
      duration: theme.transitions.duration.shortest,
    }),
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },

  [`& .${classes.singleLineText}`]: {
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  [`& .${classes.singleLineCode}`]: {
    margin: "0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export function DataListItem({
  value,
  label,
  className,
  onClick,
  ...otherProps
}: {
  value: number | string | object | null | undefined;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
}) {
  const [isCopiedTooltipShown, setIsCopiedTooltipShown] = useState(false);
  const isJSON = typeof value === "object";
  const textValue = useMemo(
    () =>
      typeof value === "string"
        ? value
        : typeof value === "number"
          ? value.toString()
          : JSON.stringify(value),
    [value],
  );

  const handleClickCopy = useCallback(() => {
    copy(textValue);
    setIsCopiedTooltipShown(true);
    setTimeout(() => {
      setIsCopiedTooltipShown(false);
    }, 2000);
  }, [textValue]);
  const handleTooltipClose = useCallback(() => {
    setIsCopiedTooltipShown(false);
  }, []);

  const { t } = useTranslation();

  return (
    <StyledListItem
      dense
      onClick={onClick ? onClick : handleClickCopy}
      className={`${className ?? ""} ${classes.root}`}
      {...otherProps}
    >
      <ListItemText
        primary={
          <Tooltip
            title={t("datalist_item.copied")}
            aria-label={t("datalist_item.copied")}
            onClose={handleTooltipClose}
            open={isCopiedTooltipShown}
            placement="top-start"
            arrow
          >
            {isJSON ? (
              <pre className={classes.singleLineCode}>{textValue}</pre>
            ) : (
              <span className={classes.singleLineText}>{textValue}</span>
            )}
          </Tooltip>
        }
        secondary={label}
      />
    </StyledListItem>
  );
}
