import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LicenseInfo } from "@mui/x-license-pro";
import {
  Location,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
import decodeJWT from "jwt-decode";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackRounded from "@mui/icons-material/ArrowBackRounded";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { TAsyncResult, useAsyncValue } from "./useAsyncValue";
import { routes } from "./routes";
import { getTenantsFromToken, TAccessToken } from "./auth";
import { ToastMessage } from "./ToastMessage";
import { NavigationMenu, useNavigationMenuState } from "./NavigationMenu";
import { TLocationState } from "./types";
import { Box, CircularProgress } from "@mui/material";
import { APIProvider, createAPI } from "./api";
import { DEFAULT_TENANT_NAMES } from "./config";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";

// activate MUI-X license (https://mui.com/x/introduction/licensing/#license-key-installation)
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSEKEY ?? "unknown");

function App() {
  const {
    // user,
    isAuthenticated,
    error: authError,
    loginWithRedirect,
    logout,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();

  const handelLogoutClick = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);
  const handelLoginClick = useCallback(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  const [accessTokenPromise, setAccessTokenPromise] =
    useState<Promise<TAccessToken> | null>(null);
  useEffect(() => {
    if (isAuthenticated) {
      setAccessTokenPromise(
        (async () => {
          const token = await getAccessTokenSilently();
          const decoded = decodeJWT<TAccessToken["decoded"]>(token);
          const tenants = getTenantsFromToken(decoded.permissions);
          if (tenants.length === 0) {
            throw Error("Account is not authorized to manage any tenants.");
          }
          const accessToken: TAccessToken = {
            token,
            decoded,
          };
          return accessToken;
        })(),
      );
    }
  }, [getAccessTokenSilently, isAuthenticated]);
  const accessTokenResult = useAsyncValue(accessTokenPromise);

  const { tenants, initialTenant, setSelectedTenant } =
    useTenants(accessTokenResult);

  const api = useMemo(() => {
    if (accessTokenResult.type === "RESOLVED") {
      return createAPI(accessTokenResult.value);
    }
    return null;
  }, [accessTokenResult]);

  const location: Location<TLocationState> = useLocation();

  const matchingRoute = useMemo(
    () =>
      routes
        .map((route) => {
          const match = matchPath(
            {
              path: route.path,
              caseSensitive: false,
              end: true,
            },
            location.pathname,
          );
          return match ? { match, route } : null;
        })
        .filter(Boolean)[0] ?? null,
    [location.pathname],
  );

  const needsAuthorization = matchingRoute?.route.authenticated !== false;

  useEffect(() => {
    if (needsAuthorization && !isLoading && !isAuthenticated) {
      loginWithRedirect({ appState: { returnTo: location.pathname } });
    }
  }, [
    isAuthenticated,
    isLoading,
    location.pathname,
    loginWithRedirect,
    matchingRoute,
    needsAuthorization,
  ]);

  const dataResultPromise = useMemo(
    () =>
      matchingRoute && api && "loadData" in matchingRoute.route
        ? matchingRoute.route.loadData?.(api, matchingRoute.match.params as any)
        : null,
    [api, matchingRoute],
  );

  const dataResult = useAsyncValue<any>(dataResultPromise);
  const navigationMenuState = useNavigationMenuState();
  const tenantKeyFromRouteParams = matchingRoute?.match.params.tenantKey;

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const redirectPath = matchingRoute?.route.redirect?.(
      matchingRoute.match.params,
      initialTenant,
    );
    if (redirectPath) {
      const redirect =
        typeof redirectPath === "string"
          ? { to: redirectPath, replace: false }
          : redirectPath;
      navigate(redirect.to, { replace: redirect.replace });
    } else if ((api || !needsAuthorization) && !matchingRoute) {
      navigate(
        tenantKeyFromRouteParams
          ? `/${tenantKeyFromRouteParams}/locations`
          : "/",
      );
    }
  }, [
    api,
    initialTenant,
    matchingRoute,
    navigate,
    needsAuthorization,
    tenantKeyFromRouteParams,
  ]);

  return (
    <>
      {location.state && "message" in location.state && (
        <ToastMessage
          message={location.state.message.text}
          severity={location.state.message.severity}
        />
      )}
      <AppBar position="sticky" sx={{ boxShadow: "none", height: "64px" }}>
        <Toolbar>
          {matchingRoute?.route.backPath && (
            <Link
              to={matchingRoute.route.backPath}
              style={{ color: "inherit" }}
            >
              <IconButton
                edge="start"
                color="inherit"
                sx={{ marginRight: 2 }}
                size="large"
              >
                <ArrowBackRounded />
              </IconButton>
            </Link>
          )}
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginLeft: matchingRoute?.route.backPath ? 0 : "52px",
            }}
          >
            {matchingRoute?.route.Title && (
              <matchingRoute.route.Title
                dataResult={dataResult as TAsyncResult<any>}
                routeParams={matchingRoute.match.params}
              />
            )}
          </Typography>
          <IconButton
            sx={{ marginRight: 2 }}
            color="inherit"
            aria-label={t("app_menu.menu")}
            onClick={navigationMenuState.open}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <NavigationMenu
            navigationMenuState={navigationMenuState}
            tenants={tenants}
            selectedTenant={tenantKeyFromRouteParams ?? initialTenant}
            onTenantSelected={setSelectedTenant}
          />
          {isAuthenticated ? (
            <Button color="inherit" onClick={handelLogoutClick}>
              {t("common.logout")}
            </Button>
          ) : (
            <Button color="inherit" onClick={handelLoginClick}>
              {t("common.login")}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container
        sx={{
          padding: "0 !important",
          margin: "0 !important",
          backgroundColor: "primary.main",
          height: "calc(100vh - 64px)",
          width: "100%",
          maxWidth: "100%",
          minWidth: "100%",
        }}
      >
        <Box
          sx={{
            margin: "0 15px 20px 15px !important",
            padding: "0 !important",
            height: "calc(100% - 20px)",
            width: "calc(100% - 30px)",
            borderRadius: "10px",
            overflow: "scroll",
            backgroundColor: "#ebecea",
          }}
        >
          {authError && <ToastMessage message={authError} severity="error" />}
          {api || !needsAuthorization ? (
            // The "api" object above only exists if login was successful,
            // i.e. accessTokenResult.type === "RESOLVED".
            <APIProvider value={api}>
              {matchingRoute?.route.Content ? (
                <matchingRoute.route.Content
                  dataResult={dataResult}
                  routeParams={matchingRoute.match.params}
                />
              ) : null}
            </APIProvider>
          ) : accessTokenResult.type === "REJECTED" ? (
            <ToastMessage message={accessTokenResult.error} severity="error" />
          ) : (
            // Here, either accessTokenResult.type is "PENDING" or it's "NONE"
            // (which would mean that the login is still in progress).
            <CircularProgress
              sx={{
                display: "block",
                margin: "auto",
                marginTop: 4,
                marginBottom: 4,
              }}
            />
          )}
        </Box>
      </Container>
    </>
  );
}

const useTenants = (
  accessTokenResult: TAsyncResult<TAccessToken>,
): {
  tenants: string[];
  initialTenant: string | null;
  setSelectedTenant: (tenant: string) => void;
} => {
  const tenants = useMemo(() => {
    if (accessTokenResult.type === "RESOLVED") {
      const tenants = getTenantsFromToken(
        accessTokenResult.value.decoded.permissions,
      );
      if (tenants.length === 0) {
        // TODO(goderbauer): Surface this error in UI.
        throw Error("User is no authorized to manage any tenants.");
      }
      return tenants;
    }
    return [];
  }, [accessTokenResult]);
  const initialTenant = useMemo(() => {
    return (
      DEFAULT_TENANT_NAMES.find(
        (defaultTenantName) => tenants.includes(defaultTenantName),
        [],
      ) ??
      tenants[0] ??
      null
    );
  }, [tenants]);

  const navigate = useNavigate();
  const setSelectedTenant = useCallback(
    (tenantKey: string) => {
      navigate(`/tenants/${tenantKey}`);
    },
    [navigate],
  );

  return {
    tenants,
    initialTenant,
    setSelectedTenant,
  };
};

export default App;
