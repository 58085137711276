export const assertEnv = <TDefaultValue = never>(
  value: string | undefined,
  envName: string,
  defaultValue?: TDefaultValue,
): string | TDefaultValue => {
  if (value) {
    return value;
  }
  if (typeof defaultValue !== "undefined") {
    return defaultValue;
  }

  throw new Error(`Expected process.env.${envName} to be present but wasn't`);
};

export const assertEnvNumber = (
  value: string | undefined,
  envName: string,
): number => {
  const stringValue = assertEnv(value, envName);
  const numberValue = parseInt(stringValue);

  if (Number.isNaN(numberValue)) {
    throw new Error(
      `Expected process.env.${envName} to be a number but got "${stringValue}" (${numberValue})`,
    );
  }

  return numberValue;
};

export const assertEnvBoolean = (
  value: string | undefined,
  envName: string,
): boolean => {
  const stringValue = assertEnv(value, envName);
  const isTrue = stringValue === "true";
  const isFalse = stringValue === "false";

  if (!isTrue && !isFalse) {
    throw new Error(
      `Expected process.env.${envName} to be a boolean string but got "${stringValue}"`,
    );
  }

  return isTrue;
};

export const assertEnvStringArray = (
  value: string | undefined,
  envName: string,
): string[] => {
  const stringValue = assertEnv(value, envName);
  const split = stringValue.split(",");

  if (split.some((item) => item === "")) {
    throw new Error(
      `Expected process.env.${envName} to be a comma-separated list of strings but got "${stringValue}"`,
    );
  }

  return split;
};

export const DEFAULT_TENANT_NAMES = assertEnvStringArray(
  process.env.REACT_APP_DEFAULT_TENANT_NAMES,
  "REACT_APP_DEFAULT_TENANT_NAMES",
);
