import {
    Box,
} from "@mui/material";
import { TLocation, getLocations, useAPI, TAPI, getLocation,  } from "../api";
import { RequestErrorToast } from "../ToastMessage";
import { TAsyncResult, useAsyncValue } from "../useAsyncValue";
import { doesTokenAllowOpenLocationOrAnyDoor } from "../auth";
import { useMemo } from "react";
import { TRoute } from "../types";
import { useTranslation } from "react-i18next";
import { LocationSidebar } from "./LocationSidebar";
// import { AreaSidebar } from "./AreaSidebar";
import { LocationDetailsContent } from "../location-details";
import {styled} from "@mui/material/styles";

const LocationBox = styled(Box)(({ theme }) => ({
    '@media (max-width: 792px)': {
        'div': {
            width: '100% !important',
        },
        width: '100% !important',
    },
    '@media (min-width: 792px)': {
        display: 'block !important',
    }
}));
const DoorBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    overflow: 'scroll'
}));

export type TDoorsOverviewRouteData = TLocation[];

type TDoorsOverviewRouteParams = {tenantKey: string; locationID: string; areaID: string};

export function loadLocations(
    api: TAPI,
    { tenantKey }: TDoorsOverviewRouteParams,
) {
    return getLocations(api, tenantKey);
}

export function OverviewTitle(props: {
    dataResult: TAsyncResult<TDoorsOverviewRouteData>;
    routeParams: TDoorsOverviewRouteParams;
}) {
    const {t} = useTranslation();

    return <>{props.routeParams.tenantKey ?? t('common.tenant')}</>;
}

export function OverviewContent({
     dataResult,
     routeParams,
 }: {
    dataResult: TAsyncResult<TDoorsOverviewRouteData>;
    routeParams: TDoorsOverviewRouteParams;
}) {
    const api = useAPI();
    const filteredLocations = useMemo(
        () =>
            dataResult.type !== "RESOLVED"
                ? []
                : dataResult.value.filter((location) =>
                    doesTokenAllowOpenLocationOrAnyDoor(
                        api.getPermissions(),
                        location.locationID,
                    ),
                ),
        [api, dataResult],
    );
    const locationData = useAsyncValue(useMemo(() => getLocation(api, routeParams.tenantKey, routeParams.locationID),
        [api, routeParams.tenantKey, routeParams.locationID])
    );

    if (dataResult.type === "NONE") {
        return null;
    }

    if (dataResult.type === "REJECTED") {
        return <RequestErrorToast requestError={dataResult.error} />;
    }

    return (
        <Box
            sx={{ display: "flex", flexDirection: 'row', width: '100%', height: '100%'}}
        >
            <LocationBox style={{
                display: routeParams.locationID ? 'none' : 'block'
            }}>
                <LocationSidebar locations={filteredLocations} routeParams={routeParams}/>
            </LocationBox>
            {/* routeParams.locationID && <AreaSidebar areas={[]} routeParams={routeParams} /> */}
            {routeParams.locationID && routeParams.tenantKey &&
                <DoorBox>
                    <LocationDetailsContent
                        dataResult={locationData}
                        routeParams={routeParams}
                    />
                </DoorBox>
            }
        </Box>
    );
}

export const tenantOverviewRoute: TRoute<
    any,
    { tenantKey: string; locationID: string; areaID: string }
> = {
    path: "/tenants/:tenantKey/overview/",
    Title: OverviewTitle,
    Content: OverviewContent,
    loadData: loadLocations,
};
export const locationOverviewRoute: TRoute<
    any,
    { tenantKey: string; locationID: string; areaID: string }
> = {
    path: "/tenants/:tenantKey/overview/location/:locationID",
    Title: OverviewTitle,
    Content: OverviewContent,
    loadData: loadLocations,
};
export const areaOverviewRoute: TRoute<
    any,
    { tenantKey: string; locationID: string; areaID: string }
> = {
    path: "/tenants/:tenantKey/overview/location/:locationID/area/:areaID",
    Title: OverviewTitle,
    Content: OverviewContent,
    loadData: loadLocations,
};

export const overviewRoute: TRoute<any, any> = {
    path: "/overview",
    redirect: (params, selectedTenant) =>
        selectedTenant ? `/tenants/${selectedTenant}/overview` : null,
};
