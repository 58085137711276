import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";

import { TOrder, TOrder$RegiondoOrder } from "../api";

import { DataListItem } from "../DataListItem";
import { OrderItemCard } from "./OrderItemCard";
import { RentalItemCard } from "./RentalItemCard";
import { useTranslation } from "react-i18next";

const PREFIX = "OrderDetailsSections";

const classes = {
  section: `${PREFIX}-section`,
  sectionHeadline: `${PREFIX}-sectionHeadline`,
  dataCardGrid: `${PREFIX}-dataCardGrid`,
  dataListItemGroup: `${PREFIX}-dataListItemGroup`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.section}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.sectionHeadline}`]: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.dataCardGrid}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridGap: theme.spacing(1),
  },

  [`& .${classes.dataListItemGroup}`]: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "min-content auto",
    gridGap: theme.spacing(2),
    gridColumnGap: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    alignItems: "center",
    "& > *:first-child": {
      whiteSpace: "nowrap",
    },
  },
}));

const knownContactFields: {
  [key in keyof TOrder$RegiondoOrder["contact_data"]]: string;
} = {
  lastname: "Nachname",
  firstname: "Vorname",
  email: "E-Mail-Adresse",
  telephone: "Telefon",
};

export function OrderDetailsSections({ order }: { order: TOrder }) {
  const { t } = useTranslation();
  return (
    <Root>
      <Box className={classes.section}>
        <Typography variant="h4" className={classes.sectionHeadline}>
          {t("common.common")}
        </Typography>
        <Paper>
          <List dense>
            <DataListItem
              value={order.tikiOrder.location.name}
              label={t("common.location")}
            />
            <Divider />
            {(
              Object.keys(order.regiondoOrder.contact_data) as Array<
                keyof typeof order.regiondoOrder.contact_data
              >
            ).map((contactDataKey) => (
              <DataListItem
                key={contactDataKey}
                value={order.regiondoOrder.contact_data[contactDataKey]}
                label={knownContactFields[contactDataKey] ?? contactDataKey}
              />
            ))}

            <Divider />

            {order.regiondoOrder.discount_info.map((discoutInfo, index) => (
              <Box className={classes.dataListItemGroup} key={index}>
                <Box>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >{`${t("order_details.discount")} ${index + 1}`}</Typography>
                </Box>
                <Box>
                  <DataListItem
                    value={discoutInfo.code}
                    label={t("order_details.code")}
                  />
                  <DataListItem
                    value={discoutInfo.amount}
                    label={t("order_details.amount")}
                  />
                </Box>
              </Box>
            ))}

            <Divider />

            <DataListItem
              value={order.regiondoOrder.grand_total}
              label={t("order_details.total_amount")}
            />
          </List>
        </Paper>
      </Box>
      {order.tikiRental && order.tikiRental.length > 0 && (
        <Box className={classes.section}>
          <Typography variant="h4" className={classes.sectionHeadline}>
            {`${t("order_details.tiki_rental")} (${order.tikiRental.length})`}
          </Typography>
          <Box className={classes.dataCardGrid}>
            {order.tikiRental.map((tikiRental) => {
              <Typography variant="h4" className={classes.sectionHeadline}>
                {`${t("order_details.tiki_rental")} (${tikiRental.rentalID})`}
              </Typography>;
              return (
                <RentalItemCard
                  key={tikiRental.rentalID}
                  rentalItem={tikiRental.rentalItems[0]}
                />
              );
            })}
          </Box>
        </Box>
      )}
      <Box className={classes.section}>
        <Typography variant="h4" className={classes.sectionHeadline}>
          {`${t("common.items")} (${order.regiondoOrder.items.length})`}
        </Typography>
        <Box className={classes.dataCardGrid}>
          {order.regiondoOrder.items.map((item) => {
            return (
              <OrderItemCard
                key={item.unique_item_id}
                tikiOrder={order.tikiOrder}
                regiondoOrderItem={item}
              />
            );
          })}
        </Box>
      </Box>
    </Root>
  );
}
